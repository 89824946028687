import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '7pZ3rXcUyTGECzl1fFMf9X',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - SLOVAKI',
        slides: [
          {
            id: 'QJ9Ui9uFIs9Bx6LvcldHp',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5WwOHzU1BpdVJeFZBzvMMX/0859c96ca5f8d970eb26f8a1aadc2a9c/GENERIC_HPBANNERS_iPhone13_Desktop_SK.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1hCSiACe2KJv6rIccECcCR/28de284af42be9b6b3c0aceb7ce004e8/GENERIC_HPBANNERS_iPhone13_Mobile_SK.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SK - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '4uiI3qlNFHYoM0XHOjDwlV',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6iSywHlIhV2NqiFdjUVEuS/582cd9f8a45885ddc7381dfdc295b254/Good_Deals_HP_Desktop_SK.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2I3kXxyJdmfP9skLGwgFaD/77e2cac009cb840848b5aee64fe3f00c/Good_Deals_HP_Mobile_SK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SK - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.sk/sk-sk/e/good-deals',
            },
          },
          {
            id: '1ePRn5TAkiOuIqy2iSk1Vo',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3oMXtcaqMJIVAk5udhoXb7/d7e8467027bd5315c4707de2834055c6/AboutUs_HP_Desktop_SK.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3yvKGCZ8xToitfSZt5G3es/8c4a4e170bad7c6f2c6970abaaa593ec/Copy_of_VISION_Mobile_SK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SK - Vision',
            },
            link: {
              href: 'https://www.backmarket.sk/sk-sk/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4OZlQkCg0UVWx8v7R2vxOw',
      type: 'Categories',
      props: {
        title: 'Ťažké váhy',
        subtitle: 'Popularitou porazia aj Tomiho Kida',
        categories: [
          {
            id: '5Mms4tcOuwpblVr3OMVOr5',
            title: 'Smartfóny',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1yMN5P5E21cFdEIJxhGru1/c0350a66925b71ef2a6fb33d5c883c0e/iphone_11_pro_picture.jpeg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6y9fjqnXZyL2xSS78pXAxl/794e645c49ae48a031596b142726ba1d/iphone_11_pro_picture.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: '',
            },
            tagline: 'Ešte viac smart ako tie úplne nové. Nemyslíte?',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/smartfony/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '3cCR9GxB4GiV1UsHTJb9ZV',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/27ZSBQZSKhuRA2NGVNXJqz/ea6c699bbc89546caf8f6ea049120e4b/MacBook_Air_M1_2020.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Air M1',
            },
            tagline:
              'MacBook Air a MacBook za ceny, z ktorých by sa sám Tim Cook červenal',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/vsetky-macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
            },
          },
          {
            id: '1IehY3DnsosYIN5DbCMwMg',
            title: 'Tablety',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7njvtzlzSDJkzMQnDmmujF/3c55c8b896d115867ba5024155ae0e02/TABLET_picture.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '',
            },
            tagline:
              'So zľavami až 40 %... Aby ostalo aj na predplatné Netflixu.',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/tablety/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: '23RcK4OuS8neHwG4kEqSAv',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/205d8b576c335777242d184faa67b945/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Pozor, vysoko nadčasové',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3LjH3uk6LjNrLaBzGCHcid',
      type: 'Categories',
      props: {
        title: 'Ďalšie kategórie',
        subtitle: 'Nič nové, zato všetko poctivo repasované',
        categories: [
          {
            id: '32juh8zkv7EWQCXC9Qi5Ef',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1ORhFpfN5jvxd149qrpAl5/5a52cb72a163e2f565b2acff1efd55dc/None_ca03a5ff-7ab6-4869-b6c2-976b0c08e32a-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12 KN',
            },
            tagline: 'Najchutnejšie jablká na trhu',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: 'vCCK8jXMRGpEux6IDE1a2',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51m1oAq9D6nbi1QilyDi66/f6d75845388276570539e4778683af1f/S10.jpeg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2TAGOlSlWibkfAPvICZ9Ya/f2df63a80eb252d7118be66e8f72dd81/S10.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'Galaxy',
            },
            tagline: 'Preskúmajte rozľahlú galaxiu za horizontom Apple',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/smartfon-samsung/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: 'mge5PAoMNJ1lrVSSQ22tW',
            title: 'AirPods',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/21bd82695572c4387e807e632b6f7981/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods Pro',
            },
            tagline: 'Najštýlovejší doplnok – teda až po ponožkách v sandáloch',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/airpods/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: '566B8ipVgwsa2uzyeQDuyz',
            title: 'Počítače',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/f15627d3c86b197072b48a5fd003409e/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'Keď už stačilo skrolovania na mobile',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/notebook/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
          {
            id: '1ck5UJh1Kj3DKQMdTFMl6j',
            title: ' Fotoaparáty',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51pMifL5BxMUDHSVwyAeBE/8e11bc4c5c3c97d806ebca26f5b9d5f0/129798_a2cc9fc1-07fb-47cf-8613-ff82a354a3f8-thumb.jpeg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5T5ucA4BO8D0FC6BUnYB8a/f5d02257352208c1edbe5d3b8e2fdfe9/esfNikon.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'ES- BF Nikon',
            },
            tagline: 'Najdlhší bleskový výpredaj v dejinách',
            link: {
              href: 'https://www.backmarket.sk/sk-sk/l/fotografia-fotoaparaty-a-objektivy/47d8e593-9912-4432-879f-4ff726e92bbc',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '6U1BS4yxbQKvftpVfjq9eG',
      type: 'Faq',
      props: {
        title: 'Čo ďalšie?',
        subtitle: '4 otázky, ktoré sa ľudia vždy pýtajú',
        questions: [
          {
            id: '4GnPXu05y9DM0euaBkjI2A',
            title:
              'Ako môžem mať istotu, že mi zariadenie o mesiac neprestane fungovať?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Zaručuje to proces kontroly pred uvedením predajcu, kedy technici Back Market (pod tajným kódovacím označením) objednajú od každého predajcu „tajnú objednávku“. Vďaka tomu vieme na 110 % zaručiť, že ponúkame presne sľubovanú techniku. Okrem toho máte k dispozícii 30 dní na vrátenie spolu s 1-ročnou zárukou.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '48DPTJ2apjgQJLXpuQKc9H',
            title: 'Aký je rozdiel medzi repasovaným a novým?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Repasované smartfóny navonok vyzerajú (a fungujú) ako nové. Záleží však na tom, čo je v nich. Repasovaná technika – smartfóny, tablety, laptopy a počítače – majú omnoho menší vplyv na životné prostredie než úplne nové zariadenia.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1QmdUC0GDYXKpYlshW5Gp8',
            title: 'Počkať, počkať. Čo ešte predávate?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Planéte môžete uľaviť viacerými spôsobmi. Čo sa týka techniky, neponúkame len naleštené smartfóny. V ponuke s najväčšou pravdepodobnosťou nájdete aj herné systémy, kulmy, či detské monitory alebo kávovary (nemáte za čo, mladé mamičky).',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6xtZcuUxc5KLSJ6SD4VpqO',
            title:
              'Dá sa T-800 z filmu Terminátor 2: Deň zúčtovania (1991) nazvať v podstate „repasom“?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Aby sme mohli s istotou odpovedať, museli by sme Skynet podrobiť nášmu procesu certifikácie. Určite však vieme povedať, že výrok „Já se vrátím“ je na mieste.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.sk/sk-sk/help',
          },
          label: 'Nájdite odpovede',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Domov',
  meta: {
    title: '(super)market renovovaných produktov',
    description:
      'Užite si repasované produkty na Back Market, s cenou až o 70% nižšou ako nové. Doprava zdarma - Záruka až 12 mesiacov - Zabezpečená platba s možnosťou 3 - 4 splátok. ',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/baf3a88c94a92101a163ddfb039cde64/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: '(super)market renovovaných produktov',
  titleBreadcrumb: 'Domov',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
